<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button @click="addProduct" v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add"
                           icon="el-icon-plus" type="primary">新增
                </el-button>
            </search>
        </div>
        <div id="tableList" class="mt-3">
            <my-table row-key="product_id" :data="tableData" :fields="showFields">
                <el-table-column slot="operation" width="150" label="操作">
                    <template slot-scope="scope">
<!--                        <span class="my-sort-dom" :data-id="scope.row.product_id">-->
<!--                            <i class="el-icon-rank"></i>-->

<!--                        </span>-->
                        <el-button
                            size="mini"
                            :data-id="scope.row.product_id"
                            class="my-sort-dom"
                            v-access="{url:baseUrl,method:'PUT'}"
                            icon="el-icon-rank">
                        </el-button>
                        <el-button
                            size="mini"
                            @click="editAd(scope.$index)" v-access="{url:baseUrl,method:'PUT'}"
                            icon="el-icon-edit"></el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
import Sortable from 'sortablejs';

export default {
    name: "appSms",
    components: {
        search, MyTable
    },
    created() {

    },
    mounted() {
        let tbody = document.getElementById('tableList').getElementsByTagName('tbody')[0];
        tbody=document.querySelector('#tableList').querySelector('tbody');
        Sortable.create(tbody, {
            // draggable: '.rank'
            //  disabled: false, // 是否开启拖拽
            handle: '.my-sort-dom',
            ghostClass: 'sortable-ghost', //拖拽样式
            animation: 150, // 拖拽延时，效果更好看
            group: { // 是否开启跨表拖拽
                pull: false,
                put: false
            },
            onEnd: () => {
                let order = [];
                let index=0;
                [...tbody.getElementsByClassName('my-sort-dom')].map(v => {
                    order.push({product_id: v.dataset.id, sort_order: index++});
                    if (v.dataset.id==='6358a206a4ce6'){
                        console.log(index);
                    }
                });
                this.$app.put(this.baseUrl,{action:'sort_order',order:order}).then(()=>{

                });
            }
        });
    },
    data: function () {
        return {
            baseUrl: '/bigmap/product/index',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            sum: {},
            subscribe: [],
            userGroup: [],
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('productList', 'search');
        },
        showFields: function () {
            return F.show('productList', 'show');
        },
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$app.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.tableData = data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editAd':
                    this.$app.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.index, 1, adData.data);
                    });
                    break;
                case 'addProduct':
                    this.$app.post(this.baseUrl, data.data).then(userData => {
                        this.page.total++;
                        this.$message.success('创建成功!');
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }
            this.form.show = false;
        },
        editAd: function (index) {
            let form = F.get('productList', 'edit', this.tableData[index]);
            this.actionName = 'editAd';
            this.form = form;
            this.index = index;
        },
        addProduct: function () {
            let form = F.get('productList', 'add');
            this.actionName = 'addProduct';
            this.form = form;
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.rank {
    display: inline-block;
    margin-right: 10px;
}
</style>
